<template>
<div>
	<CRow>
		<CCol sm="12" md="8">
        <CCard>
          <CCardHeader>
            <h4>New Card </h4>      
          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>
            <CForm>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Card Name</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-input v-model="cardName" placeholder="Enter Card Name"></b-form-input>
                   </b-col>                  
              </b-row><br/>

               <CTextarea
                label="Description"  v-model="cardDescription" placeholder="Enter Description"
                horizontal  rows="3"               
               />

              <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9"> 
                       <b-button-group>
                         <router-link to="/greetingcard" tag="b-button">Cancel</router-link>
                         <b-button  v-on:click="ConfirmGCard()" variant="primary">Save</b-button>
                       </b-button-group>    
                  </b-col>                  
              </b-row><br/>
            </CForm>         
           </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import TrService from '@/api/TransactService.js';

  export default {    
    data() {
      return {  
          cardName:'', 
          cardDescription:'',
          seen:false,
          msg :'',
          color:''          
        };
    },
    created () {   
               
    },
    methods: {

      ConfirmGCard(){ 
         
        let params = {}; var validate = [];
        params['name']   = this.cardName;
        params['description'] = this.cardDescription;    
        params['recordStatus'] = "ACTIVE";      

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        if(!validate.includes(false)){   //console.log(params); 
            //if(localStorage.getItem('sa')==="true"){  
              this.CreateGCard(params); 
            // } else { 
            //   this.msg   = 'Sorry, your role not allowed this action!'; 
            //   this.color = 'warning'; this.seen  = true;
            // }            
        } else {
           this.msg   = 'Data Not Complete, All field required!'; 
           this.color = 'warning'; this.seen  = true;          
        } 

        setTimeout( () =>  this.seen=false , 5000);
      },   

      CreateGCard: function(params) { 
          TrService.CreateGCard(params).then(resp => {   //console.log(resp);           

            if(resp.message=="Success"){
                this.msg   = 'Create Greeting Card Successfully'; 
                this.color = 'success';  this.seen  = true;
              } else {
                this.msg   = !resp.data.message ? "Create Greeting Card Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  
              }
            
           }, error => {
            console.log('error');    
          });
      },        
      
    }
  }
</script>